<template>
  <div>
    <header class="header">
      <div class="header-text">付款给 H5xxx</div>
      <div class="header-img">
        <img :src="avatar ? avatar : icon_member_default" alt="">
      </div>
    </header>
    <div class="plus-input">
      <!-- ￥字符 货币的符号-->
      <div class="S">
        <img src="../../assets/icon/S.svg" alt="">
      </div>

      <!-- 输入框光标 -->
      <!-- <div class="ttt">
        <div
          class="input-c-div"
          style="background: #07c160"
        ></div>
      </div> -->

      <!-- 手写输入框 -->
      <div class="input-c">
        <div class="input-c-div-1" v-show="amount">{{ amount/100 }}</div>
        <!-- 数字金额后边的光标 -->
        <!-- <div class="input-c-div" style="background:#07c160"></div> -->
      </div>
      <!-- 手写输入框的提示文字 -->
      <div v-show="!amount" class="placeholder">请输入金额</div>
    </div>
    <ul class="plus-ul">
      <!-- 支付板块 -->
      <li
        class="plus-li"
      >
        <!-- 支付金额板块 -->
        <div class="img-div">
          <img :src="wxImg" alt="">
          <div class="div-text">
            微信支付
          </div>
        </div>
      </li>
      <li
          class="plus-li"
      >
        <!-- 支付金额板块 -->
        <div class="img-div">
          <img :src="aliImg" alt="">
          <div class="div-text">
            支付宝
          </div>
        </div>
      </li>
    </ul>
    <!-- 备注板块 ，目前不需要添加备注，隐藏-->
<!--    <div class="remark-k" :class="payType != 'wx' ? 'margin-top-30' : ''">-->
<!--      <div class="remark">-->
<!--        <div class="remark-hui" v-show="remark">{{ remark }}</div>-->
<!--        <div >{{ remark ? "修改" : "添加备注" }}</div>-->
<!--      </div>-->
<!--    </div>-->
    <!-- dialog 对话框 目前不需要添加备注，隐藏-->
    <!-- <MyDialog
      v-show="myDialogState"
      @myDialogStateFn="myDialogStateFn"
      :remark="remark"
    >
    </MyDialog> -->

    <!-- 键盘板块 目前不需要键盘 隐藏 -->
    <!-- <div class="keyboard-plus" v-if="isAllowModifyAmount">
      <Keyboard
        @delTheAmount="delTheAmount"
        @conceal="conceal"
        @enterTheAmount="enterTheAmount"
        @payment="payment"
        :money="money"
        :concealSate="concealSate"
        :typeColor="typeColor[payType]"
      ></Keyboard>
    </div> -->

    <!-- jeepay中，付款的点击事件 由 payment 修改为 pay  -->
    <!-- jeepay中，付款页面是唯一的，颜色不在需要v-bind，去掉即可 -->
    <!-- <div class="bnt-pay" v-if="!isAllowModifyAmount"> -->
    <div class="bnt-pay">
      <div
        class="bnt-pay-text"
        style="background-color:#07c160"
        @click="pay"
      >
        付款
      </div>
    </div>
  </div>
</template>

<script>

import config from '@/config'
export default {
  // 注册备注对话框，和 手写键盘组件，由于这里是直接掉起支付事件，所以目前不应用
  // components: { MyDialog, Keyboard },
  data: function() {
    return {
      payType:'wx',
      merchantName: 'jeepay', // 付款的商户默认
      avatar: require('../../assets/icon/wx.svg'), // 商户头像默认
      amount: null, // 支付金额默认
      resData: {},
      wxImg: require('../../assets/icon/wx.svg'), // 微信支付图片
      aliImg: require('../../assets/images/zfb.jpeg'), // 支付宝支付图片
      payOrderInfo: {} // 订单信息
    }
  },

  mounted() {
    this.setPayOrderInfo(true) // 获取订单信息 & 调起支付插件
  },

  methods: {

    setPayOrderInfo(isAutoPay) {

    }

  }

}
</script>
<style lang="css" scoped>
@import './pay.css';
</style>
